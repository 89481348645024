import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  html;
  constructor(private sanitized: DomSanitizer) {

    this.html = `<!-- HTML generated using hilite.me --><div style="background: #f8f8f8; overflow:auto;width:auto;border:solid gray;border-width:.1em .1em .1em .8em;padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #408080; font-style: italic">#!/usr/bin/python</span>
    <span style="color: #408080; font-style: italic"># -*- coding: utf-8 -*-</span>
        
    <span style="color: #008000; font-weight: bold">import</span> <span style="color: #0000FF; font-weight: bold">cherrypy</span>
    <span style="color: #008000; font-weight: bold">import</span> <span style="color: #0000FF; font-weight: bold">urllib</span>
    
    scans <span style="color: #666666">=</span> []
    
    <span style="color: #008000; font-weight: bold">class</span> <span style="color: #0000FF; font-weight: bold">Scans</span>:
        
      exposed <span style="color: #666666">=</span> <span style="color: #008000">True</span>
        
      <span style="color: #008000; font-weight: bold">def</span> <span style="color: #0000FF">GET</span>(<span style="color: #008000">self</span>):
        <span style="color: #008000; font-weight: bold">return</span> (<span style="color: #BA2121">&#39;Here are all the scans: </span><span style="color: #BB6688; font-weight: bold">%s</span><span style="color: #BA2121">&#39;</span> <span style="color: #666666">%</span> scans)
        
      <span style="color: #008000; font-weight: bold">def</span> <span style="color: #0000FF">POST</span>(<span style="color: #008000">self</span>, <span style="color: #666666">**</span>kwargs):
        <span style="color: #408080; font-style: italic"># cherrypy.response.status = 404</span>
        <span style="color: #408080; font-style: italic"># cherrypy.response.headers[&#39;Custom-Title&#39;] = urllib.parse.quote_plus(&#39;My custom error&#39;)</span>
        <span style="color: #408080; font-style: italic"># cherrypy.response.headers[&#39;Custom-Message&#39;] = urllib.parse.quote_plus(&#39;The record already exists.&#39;)</span>
                
        <span style="color: #408080; font-style: italic"># in ms, if not set automatic time depending on the length, 0 = forever</span>
        cherrypy<span style="color: #666666">.</span>response<span style="color: #666666">.</span>headers[<span style="color: #BA2121">&#39;Custom-Time&#39;</span>] <span style="color: #666666">=</span> <span style="color: #BA2121">&#39;5000&#39;</span>
        
        content <span style="color: #666666">=</span> <span style="color: #BA2121">&quot;unknown content&quot;</span>
        format <span style="color: #666666">=</span> <span style="color: #BA2121">&quot;unknown format&quot;</span>
        
        <span style="color: #008000; font-weight: bold">if</span> <span style="color: #BA2121">&quot;content&quot;</span> <span style="color: #AA22FF; font-weight: bold">in</span> kwargs:
            content <span style="color: #666666">=</span> kwargs[<span style="color: #BA2121">&quot;content&quot;</span>]
        <span style="color: #008000; font-weight: bold">if</span> <span style="color: #BA2121">&quot;format&quot;</span> <span style="color: #AA22FF; font-weight: bold">in</span> kwargs:
            format <span style="color: #666666">=</span> kwargs[<span style="color: #BA2121">&quot;format&quot;</span>]
        
        scans<span style="color: #666666">.</span>append((content, format))
        <span style="color: #008000; font-weight: bold">return</span> (<span style="color: #BA2121">&#39;Append new scan with content: </span><span style="color: #BB6688; font-weight: bold">%s</span><span style="color: #BA2121">, format </span><span style="color: #BB6688; font-weight: bold">%s</span><span style="color: #BA2121">&#39;</span> <span style="color: #666666">%</span> (content, format))
        
    <span style="color: #008000; font-weight: bold">if</span> __name__ <span style="color: #666666">==</span> <span style="color: #BA2121">&#39;__main__&#39;</span>:
        
      conf <span style="color: #666666">=</span> {
      <span style="color: #BA2121">&#39;global&#39;</span>: {
        <span style="color: #BA2121">&#39;server.socket_host&#39;</span>: <span style="color: #BA2121">&#39;0.0.0.0&#39;</span>,
        <span style="color: #BA2121">&#39;server.socket_port&#39;</span>: <span style="color: #666666">8080</span>
      },
      <span style="color: #BA2121">&#39;/&#39;</span>: {
        <span style="color: #BA2121">&#39;request.dispatch&#39;</span>: cherrypy<span style="color: #666666">.</span>dispatch<span style="color: #666666">.</span>MethodDispatcher()
      }
      }
        
      cherrypy<span style="color: #666666">.</span>quickstart(Scans(), <span style="color: #BA2121">&#39;/scans/&#39;</span>, conf)
    </pre></div>`;

    this.html = this.sanitized.bypassSecurityTrustHtml(this.html);
  }
}
